import React, {useState, useEffect} from "react"
import {Container,Row,Col,Table} from 'react-bootstrap';
import { Link } from "gatsby"
import dateFormat from 'dateformat';
import HTMLReactParser from 'html-react-parser';
import VideoImg1 from "../../assests/images/video1.jpg";
import VideoImg5 from "../../assests/images/video5.jpg";
import FeedbackIcon from "../../assests/images/feedback_icon.svg";

import CustomerFeedback from  "./customer-feedback"; 

import {config} from '../Common/constant';

import VideoModule from "../../components/modules/video-module"

import { useContainerContext } from "../../store/ContainerContext";

import Title_Block from  "../../components/modules/title_block";

import { GET_ARTICLE, GET_CSM_PROPERTY } from "../../queries/common_use_query";

import TimeTrackerComp from "./time_tracker"

const TeamPage = (props) => {

  const context = useContainerContext();
  const { property_launch_date_time, photographer_date_time } = context.state;

  const [open_feedback, setFeedback] = useState(false);
  const [csm_name, setCsmName] = useState('');
  const [property_data, setPropertyData] = useState(props.property_data);

  const [ article_data, setArticleData ] = useState('');
  const { loading, error, data } = GET_ARTICLE('client-service-manager');
  const { loading:csm_loading, error:csm_error, data:csm_data } = GET_CSM_PROPERTY(property_launch_date_time.CSM);

  useEffect(()=>{
      setArticleData(data && data.articles[0]);
  }, [data]);

  const OpenFeedback = (csm_name) => {
    setCsmName(csm_name);
    setFeedback(true);
  }

  const CloseFeedback = () => {
    setCsmName('');
    setFeedback(false);
  }

  const [ playVideo, setPlayVideo ] = useState('');

  var customer_service_manager =  csm_data && csm_data.customerServiceManagers.length > 0 ? csm_data.customerServiceManagers :  property_data && property_data.customer_service_manager1 ? property_data.customer_service_manager1 : [];

  var photographer_testi = [];

  //console.log("customer_service_manager", customer_service_manager)

    return <>
        {props.Type != "preval" && <TimeTrackerComp TabName={'Team'} /> }

        <div className={`section-wrapper ${props.Type === "preval" ? 'pt-0' : ''} ${open_feedback === true ? 'hide' : ''}`}>
          <Container>
            
            {
              props.Type != "preval" && ( <>
                <Title_Block Title={article_data && article_data.Title} Type={props.Type} PreviousLink={''} NextLink={''} instruct={true} />

                {article_data && article_data.Content && <p className="fig-content mb_cnt font_20">{HTMLReactParser(article_data.Content)}</p>}
                </>
              )
            }
            

            {
              csm_loading ? ' ' :  customer_service_manager && customer_service_manager.length > 0 && <>

                  {
                    customer_service_manager.map((csm, index) => {
                      return(
                        csm.CSSName && csm.CSSPhoto ? (
                            <div className={`profile-wrapper ${props.Type === "preview" ? 'preview_wrapper' : ''}`}>
                              <Row>
                                <Col md={6} sm={12} >
                                  <div className="profile-right">
                                    <h3>{csm.Name}</h3>
                                    <span>Client Service Manager</span>
                                      
                                      {/* {
                                        props.Type != "preval" && (
                                        <div className="feedbackLink">
                                          <a href="javascript:;" onClick={() => OpenFeedback(csm.Name)}><img src={FeedbackIcon} alt="" />My Customer Feedback</a>
                                        </div>
                                        )
                                      } */}

                                      {
                                        csm.Photo && (
                                          <div className={`video-wrap square_video team_video ${csm.IntroVideoURL ? 'video_cls' : ''}`}>
                                            {csm.IntroVideoURL ? 
                                                <VideoModule width={"100%"} height={"100%"} video_url={csm.IntroVideoURL} playing={false} loop={false} /> 
                                            : (
                                              <figure>
                                                {/* <img src={csm.Photo} alt="video-img" /> */}
                                                <div className="introImg" style={{backgroundImage:`url("${csm.Photo}")`}}></div>
                                              </figure>
                                              ) 
                                            }
                                        </div>
                                        )
                                      }


                                      <p className="fig-content">{csm.Bio}</p>

                                      {
                                        photographer_testi && photographer_testi.length > 0 && 
                                          <div className="content-sec-wrap customer_feedback">
                                              <h2>My Customer Feedback</h2>
                                              <div className="content-block-wrap">
                    
                                              {photographer_testi.map((item, index)=>{
                                                      return(
                                                        <div className="content-block">
                                                              <p>“{item.Quote}”</p>
                                                              <div className="content-bottom">
                                                                  <strong>{item.Client}</strong>
                                                                  <strong>{item.Date && dateFormat(item.Date, "mmm dd, yyyy")}</strong>
                                                              </div>
                                                          </div>
                                                      )
                                                  }) 
                                              }
                                              </div>
                                              {/* content-block */}
                                          </div>
                                      }


                                      {/* <div class="content-block">
                                        <strong>Location:</strong>
                                        <span>-</span>
                                      </div> */}
                                      {/* {
                                        csm.TelephoneNumber && (
                                          <div className="content-block">
                                            <strong>Telephone:</strong>
                                            <a href={`tel:${csm.TelephoneNumber}`}>{csm.TelephoneNumber}</a>
                                          </div>
                                        )
                                      }                         */}
                                      {/* content-block */}

                                      {/* {
                                        csm.Email && (
                                          <div className="content-block">
                                            <strong>Email Address:</strong>
                                            <a href={`mailto:${csm.Email}`}>{csm.Email}</a>
                                          </div>
                                        )
                                      }  */}
                                  </div>  
                                  {/* profile-left */}
                                </Col>


                                <Col md={6} sm={12} >
                                <div className="profile-right">
                                  <h3>{csm.CSSName}</h3>
                                  <span>Client Service Support</span>
                                    
                                    {/* {
                                      props.Type != "preval" && (
                                      <div className="feedbackLink">
                                        <a href="javascript:;" onClick={() => OpenFeedback(csm.Name)}><img src={FeedbackIcon} alt="" />My Customer Feedback</a>
                                      </div>
                                      )
                                    } */}

                                    {
                                      csm.CSSPhoto && (
                                        <div className={`video-wrap square_video team_video`}>
                                          <figure>
                                            {/* <img src={csm.CSSPhoto} alt="video-img" /> */}
                                            <div className="introImg" style={{backgroundImage:`url("${csm.CSSPhoto}")`}}></div>
                                          </figure>
                                      </div>
                                      )
                                    }


                                    <p className="fig-content">{csm.CSSBio}</p>

                                    {/* {
                                      photographer_testi && photographer_testi.length > 0 && 
                                        <div className="content-sec-wrap customer_feedback">
                                            <h2>My Customer Feedback</h2>
                                            <div className="content-block-wrap">

                                            {photographer_testi.map((item, index)=>{
                                                    return(
                                                      <div className="content-block">
                                                            <p>“{item.Quote}”</p>
                                                            <div className="content-bottom">
                                                                <strong>{item.Client}</strong>
                                                                <strong>{item.Date && dateFormat(item.Date, "mmm dd, yyyy")}</strong>
                                                            </div>
                                                        </div>
                                                    )
                                                }) 
                                            }
                                            </div>
                                        </div>
                                    } */}


                                    {/* <div class="content-block">
                                      <strong>Location:</strong>
                                      <span>-</span>
                                    </div> */}
                                    {/* {
                                      csm.TelephoneNumber && (
                                        <div className="content-block">
                                          <strong>Telephone:</strong>
                                          <a href={`tel:${csm.TelephoneNumber}`}>{csm.TelephoneNumber}</a>
                                        </div>
                                      )
                                    }                         */}
                                    {/* content-block */}

                                    {/* {
                                      csm.Email && (
                                        <div className="content-block">
                                          <strong>Email Address:</strong>
                                          <a href={`mailto:${csm.Email}`}>{csm.Email}</a>
                                        </div>
                                      )
                                    }  */}
                                </div>  
                                {/* profile-left */}
                                </Col>
                              
                                </Row>
                              </div>
                          ) : (
                          <div className="employee-wrapper single_wrap mt-5">
                            <div className="employee-content">
                            <div className="employee-top">
                              <h3>{csm.Name}</h3>
                              <span>Client Service Manager</span>
                            </div>
                            {/* top-content */}
                            
                            {/* {
                              props.Type != "preval" && (
                              <div className="feedbackLink">
                                <a href="javascript:;" onClick={() => OpenFeedback(csm.Name)}><img src={FeedbackIcon} alt="" />My Customer Feedback</a>
                              </div>
                              )
                            } */}
                            
                            <p>{csm.Bio}</p>
  
                            {
                              photographer_testi && photographer_testi.length > 0 && 
                                <div className="content-sec-wrap customer_feedback">
                                    <h2>My Customer Feedback</h2>
                                    <div className="content-block-wrap">
          
                                    {photographer_testi.map((item, index)=>{
                                            return(
                                              <div className="content-block">
                                                    <p>“{item.Quote}”</p>
                                                    <div className="content-bottom">
                                                        <strong>{item.Client}</strong>
                                                        <strong>{item.Date && dateFormat(item.Date, "mmm dd, yyyy")}</strong>
                                                    </div>
                                                </div>
                                            )
                                        }) 
                                    }
                                    </div>
                                    {/* content-block */}
                                </div>
                            }
  
                            {/* <div className="content-block">
                              <strong>Location:</strong>
                              <span>Gledhow, Leeds -</span>
                            </div> */}
                            {/* content-block */}
  
  
                              {/* {
                                csm.TelephoneNumber && (
                                  <div className="content-block">
                                    <strong>Telephone:</strong>
                                    <a href={`tel:${csm.TelephoneNumber}`}>{csm.TelephoneNumber}</a>
                                  </div>
                                )
                              }                        
  
                              {
                                csm.Email && (
                                  <div className="content-block">
                                    <strong>Email Address:</strong>
                                    <a href={`mailto:${csm.Email}`}>{csm.Email}</a>
                                  </div>
                                )
                              }  */}
                              {/* content-block */}
                            </div>
                            {/* employment-content */}
  
  
                            <div className="employment-right">
                              {
                                csm.Photo && (
                                  <div className={`video-wrap square_video team_video single_wrap ${csm.IntroVideoURL ? 'video_cls' : ''}`}>
                                    {csm.IntroVideoURL ? 
                                        <VideoModule width={"100%"} height={"100%"} video_url={csm.IntroVideoURL} playing={false} loop={false} /> 
                                    : (
                                      <figure>
                                        {/* <img src={csm.Photo} alt="video-img" /> */}
                                        <div className="introImg" style={{backgroundImage:`url("${csm.Photo}")`}}></div>
                                      </figure>
                                      ) 
                                    }
                                </div>
                                )
                              }
                            </div>
                            {/* video-wrap */}
  
                          </div>
                        )

                      )
                    })  
                  }

                </>
            }
           {/* profile-wrapper */}
          </Container>
        </div>
        {/* section-wrapper */}


        <div className={`section-wrapper customer_feedback ${open_feedback === false ? 'hide' : ''}`}>
            <Container>
              <h2>{csm_name} Customer Feedback <a href="javascript:;" className="feed_close" onClick={CloseFeedback}>X</a></h2>
              <CustomerFeedback photographer_testi={photographer_testi} />
            </Container>
        </div>      
        {/* customer feedback */}

    </>
}

export default TeamPage