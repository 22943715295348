import React from "react"
import {Container,Row,Col,Navbar,Nav} from 'react-bootstrap';
import { Link } from "gatsby"
import dateFormat from 'dateformat';

const CustomerFeedback = (props) => {

    return (
    <>    
        
        <div className="content-sec-wrap">

            <div className="content-block-wrap">
            <Row>
            {
                props.photographer_testi && props.photographer_testi.length > 0 ?
                props.photographer_testi.map((item, index)=>{
                    return(
                        <Col xs={12} md={6}>
                            <div className="content-block">
                                <p>“{item.Quote}”</p>
                                <div className="content-bottom">
                                    <strong>{item.Client}</strong>
                                    <strong>{item.Date && dateFormat(item.Date, "mmm dd, yyyy")}</strong>
                                </div>

                            </div>
                        </Col>
                    )
                }) : (
                    <Col xs={12} md={6}>
                        <div className="content-block">
                            <p>“Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s”</p>
                            <div className="content-bottom">
                                <strong>Name</strong>
                                <strong>Date</strong>
                            </div>

                        </div>
                    </Col>
                )
            }

            </Row>

            </div>
            {/* content-block */}

        </div>
        {/* content-sec-wrap */}

    </>
    )
}

export default CustomerFeedback
