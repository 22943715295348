import React, {useState, useEffect} from "react"
import {Container,Row,Col,Table} from 'react-bootstrap';
import { Link } from "gatsby"
import SEO from "../../components/seo"

import NavLink from  "../../components/NavLink/Custom_Presentation"; 
import Header from  "../../components/Common/Header"; 
import Teams from  "../../components/modules/teams"; 
import Photographer from  "../../components/modules/360-photographer"; 

import {config} from '../../components/Common/constant';


import { GET_ARTICLE} from "../../queries/common_use_query";

import { useContainerContext } from "../../store/ContainerContext";

const PresentationPage = () => {

  const context = useContainerContext();
  const { property_data, property_launch_date_time, photographer_date_time } = context.state;

  const [ article_data, setArticleData ] = useState('');
  const { loading, error, data } = GET_ARTICLE('client-service-manager');

  

  useEffect(()=>{
      setArticleData(data && data.articles[0]);
  }, [data]);


  return <>
    <SEO title={article_data && article_data.Meta_Title} description={article_data && article_data.Meta_Description} />
    <div className="pre-wrapper team_wrapper">

        <Header />

        {property_data && 
          <>
          <div className="section-wrapper custom_present_inner pt-0">
            <Teams property_data = {property_data}/> 
          </div>

          <div className="section-wrapper custom_present_inner pt-0">
            <Photographer property_data={property_data} />
          </div> 
          </>
        }


        <NavLink tab_name="Team" prevLink={property_data && parseInt(property_data.price_expectancy) > 200000 ? config.custom_plan : config.custom_prop_datetime} nextLink={config.custom_comparables} />
        {/* footer-wrapper */}
    </div>
  </>
}

export default PresentationPage